import {Trans, useTranslation} from "react-i18next";
import {useEffect} from "react";
import useAnimation from "../../utils/hooks/animate";

import "./Video.css";
import Frame from "./frame";

export const Video = () => {
    const {i18n, t} = useTranslation();
    useAnimation();
    useEffect(() => {
        document.title = t("title", {name: t("video")});
    }, [t]);
    const isRutube: boolean = i18n.language === "ru";

    return (
        <main>
            <div className="d-flex flex-sm-row flex-column mx-lg-auto page-header video-header">
                <div className="text-header">{t("video")}</div>
                <div className="dash" id="vid_dash_1"></div>
            </div>
            <div className="flex-column d-flex w-100 video-section gap-5">
                <div className="d-flex flex-sm-row flex-column justify-content-between">
                    <h3 className="text-header2 col-sm-2 col-10">
                        <Trans i18nKey={"video.header2.1"}>
                            <br/>
                        </Trans>
                    </h3>
                    <div className="d-flex flex-lg-row flex-column video-first-row position-relative">
                        <div className="position-absolute vid-parts">
                            <div className="video-text-background part1">1</div>
                            <div className="video-text-background part2">2</div>
                        </div>
                        <Frame
                            id={isRutube ? "87697113145f65ae119230cf4d03d374" : "uhAnq-gm5cQ"}
                            title={t("video.title.1.1")}
                            className="position-relative"
                            isRutube={isRutube}
                        />
                        <Frame
                            id={isRutube ? "4644a267fc243cb8e5a9ab1b060923b6" : "hN-Tm3xIaqI"}
                            title={t("video.title.1.2")}
                            className="video-first-row-second-frame position-relative"
                            isRutube={isRutube}
                        />
                    </div>
                </div>

                {[
                    ["video.header2.14", [isRutube ? "d9a24863d02b5f5a9c04fc853ac70f20" : "S69-mh2bNw8", "video.title.14"]], // prettier-ignore
                    ["video.header2.11", [isRutube ? "1d70c0905377d0eced291e72f0d03286" : "e2yjTgVeEGg", "video.title.11"]], // prettier-ignore
                    ["video.header2.12", [isRutube ? "bd8c1031df9a85b9e962bbac95ef3986" : "ieEzsmg2aM4", "video.title.12"]], // prettier-ignore
                    ["video.header2.13", [isRutube ? "4d894e98732b4c785ffd4c85bfbed8e2" : "eT7R_Sp1LWE", "video.title.13"]], // prettier-ignore
                    ["video.header2.15", [isRutube ? "97ed34aa8a50eb2618202192b26942f8" : "TNq4QO6aT80", "video.title.15"]], // prettier-ignore
                    ["video.header2.3", [isRutube ? "af6d2e68d4c4a64e403800d1796585a8" : "DlGBb8c-Bic", "video.title.3"]], // prettier-ignore
                    ["video.header2.2", [isRutube ? "a39f08d21c12973986ef3ac8bbb5bc9d" : "nfJaxQ_8MdE", "video.title.2"]], // prettier-ignore
                    ["video.header2.4", [isRutube ? "a14f85db4e31a032cf5b2e8a4e536cfc" : "ODicXEPseus", "video.title.4"]], // prettier-ignore
                    ["video.header2.5", [isRutube ? "b9fb9aacde465b7137cde8442016f909" : "H76Rd1IxzMw", "video.title.5"]], // prettier-ignore
                    ["video.header2.6", [isRutube ? "69da6e3152b09e76cb3728f1775c4709" : "ODicXEPseus", "video.title.6"]], // prettier-ignore
                    ["video.header2.7", [isRutube ? "5a48e89e6a37d09be149ce0636a617d1" : "CsfedLiytdI", "video.title.7"]], // prettier-ignore
                    ["video.header2.8", [isRutube ? "a3cea07e1f9b504baa99325be865ae41" : "rLAodqud99E", "video.title.8"]], // prettier-ignore
                    ["video.header2.9", [isRutube ? "155a2942eaec2ab4d14e5fb44439d808" : "Lc5cADa23kM", "video.title.9"]], // prettier-ignore
                    ["video.header2.10", [isRutube ? "a893267d5228ebf8a3518cbdf4512b65" : "fm1kNfpHTJQ", "video.title.10"]], // prettier-ignore
                ].map(([i18nKey, [ytId, ytTitle]], i) => (
                    <div
                        className="d-flex flex-sm-row flex-column justify-content-between position-relative"
                        key={i}
                    >
                        <h3 className="text-header2 col-sm-3 col-10">
                            <Trans i18nKey={i18nKey}>
                                <br/>
                            </Trans>
                        </h3>
                        <Frame id={ytId} title={t(ytTitle)} isRutube={isRutube}/>
                    </div>
                ))}
            </div>
        </main>
    );
};
